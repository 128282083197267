import React from 'react';
import { useScrollIndicator } from 'react-use-scroll-indicator';
import Slide from 'react-reveal/Slide';
import {Link} from 'react-router-dom';

import NavOverlay from '../components/NavOverlay';
import Footer from '../components/Footer';

function ErrorPage() {

  const [scrollWidth] = useScrollIndicator();
  
  return (
    <div>
      
      {/* Hero */}
      <section className="flex justify-center items-center flex-col h-96 lg:h-[28rem] 2xl:h-[38rem] p-2 lg:p-10">
        <div className="overflow-y-hidden">
          <Slide bottom>
            <h4 className="text-5xl font-bold">Oopsie Daisy...</h4>
          </Slide>
        </div>
        <div className="overflow-y-hidden mb-4">
          <Slide bottom delay={400}>
            <p className="text-lg text-gray-400">Looks you're trying to access something that doesn't exist.</p>
          </Slide>
        </div>
        <Link to="/"><button className="bg-black px-4 py-2 text-white font-bold hover:scale-105 transform duration-300">← Main Page</button></Link>
      </section>

      <p className="text-center bg-black text-white px-4 py-10">
        ⢕⢕⢕⢕⢕⠅⢗⢕⠕⣠⠄⣗⢕⢕⠕⢕⢕⢕⠕⢠⣿⠐⢕⢕⢕⠑⢕⢕⠵⢕<br/>
        ⢕⢕⢕⢕⠁⢜⠕⢁⣴⣿⡇⢓⢕⢵⢐⢕⢕⠕⢁⣾⢿⣧⠑⢕⢕⠄⢑⢕⠅⢕<br/>
        ⢕⢕⠵⢁⠔⢁⣤⣤⣶⣶⣶⡐⣕⢽⠐⢕⠕⣡⣾⣶⣶⣶⣤⡁⢓⢕⠄⢑⢅⢑<br/>
        ⠍⣧⠄⣶⣾⣿⣿⣿⣿⣿⣿⣷⣔⢕⢄⢡⣾⣿⣿⣿⣿⣿⣿⣿⣦⡑⢕⢤⠱⢐<br/>
        ⢠⢕⠅⣾⣿⠋⢿⣿⣿⣿⠉⣿⣿⣷⣦⣶⣽⣿⣿⠈⣿⣿⣿⣿⠏⢹⣷⣷⡅⢐<br/>
        ⣔⢕⢥⢻⣿⡀⠈⠛⠛⠁⢠⣿⣿⣿⣿⣿⣿⣿⣿⡀⠈⠛⠛⠁⠄⣼⣿⣿⡇⢔<br/>
        ⢕⢕⢽⢸⢟⢟⢖⢖⢤⣶⡟⢻⣿⡿⠻⣿⣿⡟⢀⣿⣦⢤⢤⢔⢞⢿⢿⣿⠁⢕<br/>
        ⢕⢕⠅⣐⢕⢕⢕⢕⢕⣿⣿⡄⠛⢀⣦⠈⠛⢁⣼⣿⢗⢕⢕⢕⢕⢕⢕⡏⣘⢕<br/>
        ⢕⢕⠅⢓⣕⣕⣕⣕⣵⣿⣿⣿⣾⣿⣿⣿⣿⣿⣿⣿⣷⣕⢕⢕⢕⢕⡵⢀⢕⢕<br/>
        ⢑⢕⠃⡈⢿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⢃⢕⢕⢕
      </p>

      {/* Footer */}
      <Footer m={false} />

      {/* Navbar */}
      <NavOverlay scrollWidth={scrollWidth.value} />
    </div>
  );
}

export default ErrorPage;
