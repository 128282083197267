import React, {useState} from 'react';
import { useParams, Link } from 'react-router-dom';
import { useScrollIndicator } from 'react-use-scroll-indicator';

import Slide from 'react-reveal/Slide';

import NavOverlay from '../components/NavOverlay';
import Footer from '../components/Footer';

import projectArr from '../projects/projects';

function ProjectPage() {
  
  const [scrollWidth] = useScrollIndicator();
  const { id } = useParams();
  const [project] = useState(projectArr[id]);

  return (
    <div>
      
      {/* Hero */}
      <section className="bg-black flex justify-center items-center flex-col h-96 lg:h-[28rem] 2xl:h-[38rem] p-2 lg:p-10">
        <div className="overflow-y-hidden">
          <Slide bottom>
            <img src={project["image"]} alt={project["title"]} className="h-40 mx-auto mb-2" />
          </Slide>
        </div>
        <div className="overflow-y-hidden">
          <Slide bottom>
            <h4 className="text-5xl text-center font-bold text-white">{project["title"]}</h4>
          </Slide>
        </div>
        <div className="overflow-y-hidden">
          <Slide bottom delay={400}>
            <p className="text-lg text-center text-gray-400">{project["smallDesc"]}</p>
          </Slide>
        </div>
      </section>

      <main className="lg:py-20 py-10 max-w-screen-lg mx-auto px-4 lg:grid lg:grid-cols-4">
        
        <div className="lg:col-span-3 lg:px-4">
          <p className="text-gray-800 lg:text-left text-center indent-8">{project["description"]}</p>

          <div className="mt-6">
            {project["images"].length > 0 ? project["images"].map(i => (
              <div className="my-2">
                <img style={{marginLeft: "auto", marginRight: "auto"}} src={i} alt="Images for the site" />
              </div>
            )) : ""}
          </div>
        </div>
        
        <div className="flex items-center sticky flex-col">
          <div className="flex items-center flex-col lg:border p-4 lg:sticky top-5 lg:rounded-lg lg:bg-gray-100">
            <div className="text-gray-800 text-center lg:mt-0 mt-4 lg:mb-0 mb-4 lg:text-right">
              <h3>Technologies Used:</h3>
              <p className="uppercase font-semibold lg:text-base text-xs">{project["languages"]}</p>
            </div>
            {
            project["website"]
            ?
            <a href={project["website"]} target="_blank" rel="noreferrer">
              <button className="bg-black px-4 py-2 rounded shadow-lg hover:scale-105 transform duration-300 text-white mx-auto">Visit Website</button>
            </a>
            :
            <button style={{cursor: "not-allowed"}} className="bg-black px-4 py-2 rounded shadow-lg duration-300 text-white mx-auto">NOT AVAILABLE</button>
            }
          </div>
        </div>

      </main>

      {/* View More */}
      <section className="w-full bg-gradient-to-br from-pink-400 to-red-600 flex justify-center items-center px-4 py-10 flex-col">
        <h3 className="mb-2 text-2xl font-bold text-white text-center uppercase">Have a look at more projects</h3>
        <Link to="/portfolio"><button className="bg-white px-4 py-2 transform hover:scale-105 duration-300 text-black font-bold uppercase">More →</button></Link>
      </section>

      {/* Footer */}
      <Footer m={false} />

      {/* Navbar */}
      <NavOverlay scrollWidth={scrollWidth.value} />
    </div>
  );
}

export default ProjectPage;
