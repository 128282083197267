import React, {useState} from 'react';
import Slide from 'react-reveal/Slide';
import {Link} from 'react-router-dom';

function Project({id, image, title, type, desc}) {

  const [hover, setHover] = useState(false);
  const [reveal, setReveal] = useState(false)

  return (
    <Link to={`/project/${id}`}>
      <div onMouseEnter={() => setHover(!hover)} onMouseLeave={() => setHover(!hover)} className={`duration-300 ${reveal ? "" : "overflow-x-hidden"} select-none mx-auto relative w-full lg:w-[30rem] h-[20rem] lg:h-[26rem] mt-4`}>
        <div className={`absolute h-[20rem] lg:h-[26rem] w-full lg:w-[30rem] bg-blue-500 duration-300 transform ${hover && reveal ? "translate-x-2 translate-y-2" : ""}`}></div>
        <Slide onReveal={() => setReveal(true)} wait={1200} right duration={1100}>
          <div className={`bg-black h-[20rem] lg:h-[26rem] w-full lg:w-[30rem] absolute text-gray-100 p-8 duration-300`}>
            <div className="w-full h-full relative flex justify-center items-center">
              <Slide right delay={300}>
                <h3 className="absolute top-0 left-0 font-bold text-xs uppercase">{title}</h3>
              </Slide>
              <div className="overflow-x-hidden">
                <Slide right delay={300}>
                  <img src={image} className={`h-40 lg:h-60`} alt={title}  />
                </Slide>
              </div>
              <div className="w-full absolute bottom-0 left-0 flex justify-between items-center">
                <div className="flex flex-col">
                  <h4 className="font-bold uppercase text-xs">{type}</h4>
                  <h4 className="text-gray-400 text-sm">{desc}</h4>
                </div>
                <h3 className={`transform duration-300 ${hover ? "rotate-[-20deg]" : ""}`}>→</h3>
              </div>
            </div>
          </div>
        </Slide>
      </div>
    </Link>
  );
}

export default Project;
