import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import { useLocation } from "react-router-dom";

function NavOverlay({scrollWidth}) {
  
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  
  return (
    <div className="z-10">
      <div className={`${open ? "nav-active w-screen h-screen bg-white fixed top-0 right-0 lg:p-16" : `overflow-hidden fixed bg-white top-0 nav-notactive`}`}>
        <div className={`max-w-screen-xl mx-auto w-full h-full p-4 duration-300 justify-between items-center ${open ? "flex" : "hidden"}`}>
          <div className="uppercase text-4xl font-bold flex flex-col text-gray-600">
            <Link className={`${pathname === "/" ? "text-transparent bg-clip-text bg-gradient-to-br from-pink-400 to-red-600" : "hover:text-red-300 duration-300 "}`} to="/">Home</Link>
            <Link className={`${pathname === "/portfolio" ? "text-transparent bg-clip-text bg-gradient-to-br from-pink-400 to-red-600" : "hover:text-red-300 duration-300 "}`} to="/portfolio">Portfolio</Link>
            <Link className={`${pathname === "/about" ? "text-transparent bg-clip-text bg-gradient-to-br from-pink-400 to-red-600" : "hover:text-red-300 duration-300 "}`} to="/about">About</Link>
          </div>
          <div className="lg:block hidden">
            <h3 className="text-3xl mb-2">Howdy! I'm Kobi.</h3>
            <a href="mailto:ito.kobilol@gmail.com" className="hover:text-red-600 duration-300 text-gray-600 font-bold"><i className="fas fa-at"></i> ito.kobilol@gmail.com</a>
            <div className="my-2 flex flex-col">
              <a href="https://twitter.com/truedarkness420" target="_blank" rel="noreferrer" className="text-sm text-gray-600 duration-300 hover:text-red-600"><i className="fab fa-twitter"></i> Twitter</a>
              <a href="https://linkedin.com/in/kobimo" target="_blank" rel="noreferrer" className="text-sm text-gray-600 duration-300 hover:text-red-600"><i className="fab fa-linkedin"></i> LinkedIn</a>
              <a href="https://github.com/kobito-kun" target="_blank" rel="noreferrer" className='text-sm text-gray-600 duration-300 hover:text-red-600'><i className="fab fa-github"></i> GitHub</a>
            </div>
          </div>
        </div>
      </div>

      <div className="fixed top-4 left-6">
        <Link to="/" className="text-transparent bg-clip-text bg-gradient-to-b from-pink-400 to-red-600 text-xl font-bold">Kobi Mo</Link>
      </div>

      <div className="fixed top-5 right-6 cursor-pointer" onClick={() => setOpen(!open)}>
        <div id="nav-icon4" className={`${open ? "open" : ""}`}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className="fixed bottom-0 z-100 h-2 bg-gradient-to-l from-pink-400 to-red-600 left-0 duration-100" style={{width: `${scrollWidth}%`}}></div> 
    </div>
  );
}

export default NavOverlay;
