import React from 'react';
import NavOverlay from '../components/NavOverlay';

import Slide from 'react-reveal/Slide';
import Footer from '../components/Footer';
import Project from '../components/Project';

import NubianWebP from '../assets/images/nubian.webp';
import QParcelSVG from '../assets/images/qparcel.png';
import KodiratiPNG from '../assets/images/kodirati.png';
import CalendarPNG from '../assets/images/calendar.png';
import PollifySVG from '../assets/images/1F5F3.svg';
import DavisPNG from '../assets/images/1F9D4.svg';
import SortSVG from '../assets/images/bar-chart.svg';
import AHES from '../assets/images/ahes.png';
import VITA from '../assets/images/vita.png';

import { useScrollIndicator } from 'react-use-scroll-indicator';

function PortfolioPage() {

  const [scrollWidth] = useScrollIndicator();
  
  return (
    <div>
      
      {/* Hero */}
      <section className="bg-black flex justify-center items-center flex-col h-96 lg:h-[28rem] 2xl:h-[38rem] p-2 lg:p-10">
        <div className="overflow-y-hidden">
          <Slide bottom>
            <h4 className="text-5xl font-bold text-white">Portfolio</h4>
          </Slide>
        </div>
        <div className="overflow-y-hidden">
          <Slide bottom delay={400}>
            <p className="text-lg text-gray-400">What I'm proud of.</p>
          </Slide>
        </div>
      </section>

      {/* Listings */}
      <section className="mt-10 lg:mt-20 max-w-screen-lg mx-auto mb-10">
        <div className="overflow-y-hidden mb-4 text-center">
          <Slide bottom>
            <h3 className="2xl:text-3xl text-2xl font-bold">My works / clients.</h3>
            <p className="text-gray-400">私の作品とクライアント</p>
          </Slide>
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1 lg:px-0 px-5 mb-10">
          <Project id={"nubiangallery"} desc={"Gallery, Space & Venue."} type={"Frontend & Backend"} title={"Nubian Gallery"} image={NubianWebP} />
          <Project id={"qparcel"} desc={"Parcel Delivery Service"} type={"Frontend"} title={"QParcel"} image={QParcelSVG} />  
          <Project id={"kodirati"} desc={"Java Developer."} type={"Frontend & Backend"} title={"Kodirati Portfolio"} image={KodiratiPNG} />
          <Project id={"ahes"} desc={"Household Estate Sale."} type={"Backend"} title={"AHES"} image={AHES} />
          <Project id={"vitapvpey"} desc={"Box Opening Site."} type={"Frontend"} title={"Vitapvpey Boutique"} image={VITA} />
        </div>
      </section>

      <section className="mt-10 lg:mt-20 max-w-screen-lg mx-auto lg:mb-40 mb-10">
        <div className="overflow-y-hidden mb-4 text-center">
          <Slide bottom>
            <h3 className="2xl:text-3xl text-2xl font-bold">My extras / miscs.</h3>
            <p className="text-gray-400">私のエキストラとその他</p>
          </Slide>
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1 lg:px-0 px-5 mb-10">
          <Project id={"terrydavis"} desc={"God's Lonely Programmer."} type={"Frontend"} title={"Terry Davis Tribute"} image={DavisPNG} />
          <Project id={"homeworker"} desc={"Homeworker scheduling and tracker."} type={"Frontend & Backend"} title={"Homeworker"} image={CalendarPNG} />
          <Project id={"pollify"} desc={"Real-time polling site."} type={"Frontend & Backend"} title={"Pollify"} image={PollifySVG} />
          <Project id={"sortingviz"} desc={"Simplified algorithms sorting."} type={"Frontend"} title={"Sorting Algorithms"} image={SortSVG} />
        </div>
      </section>

      

      {/* Footer */}
      <Footer />

      {/* Navbar */}
      <NavOverlay scrollWidth={scrollWidth.value} />
    </div>
  );
}

export default PortfolioPage;