// Importing Modules
import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

// Importing Pages
import MainPage from './pages/MainPage';
import PortfolioPage from './pages/PortfolioPage';
import AboutPage from './pages/AboutPage';
import ProjectPage from './pages/ProjectPage';
import ErrorPage from './pages/404Page';

// ScrollToTop
import ScrollToTop from './ScrollTop';

// Importing CSS
import './assets/css/built.css';
import './assets/css/custom.css'

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<MainPage/>} />
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/project/:id" element={<ProjectPage />} />
        <Route path="*" element={<ErrorPage/>} />
      </Routes>
    </Router>
  );
}

export default App;
