import React from 'react';
import Slide from 'react-reveal/Slide'
import Marquee from "react-fast-marquee";
import NavOverlay from '../components/NavOverlay';
import { useScrollIndicator } from 'react-use-scroll-indicator';

import { Link } from 'react-router-dom';

import Project from '../components/Project';
import Footer from '../components/Footer';

import NubianWebP from '../assets/images/nubian.webp';
import QParcelSVG from '../assets/images/qparcel.png';

function MainPage() {

  const [scrollWidth] = useScrollIndicator();
  // eslint-disable-next-line
  const epicRed = "text-transparent bg-clip-text bg-gradient-to-br from-pink-400 to-red-600 font-semibold";
  
  return (
    <div>
        
        {/* Main Container for Hero */}
        <section id="hero" className="bg-gray-50 flex justify-center items-center flex-col h-96 lg:h-[28rem] 2xl:h-[38rem] p-2 lg:p-10">
            <div className="font-bold text-3xl text-center 2xl:text-5xl select-none">
                <div className="overflow-y-hidden leading-kobi">
                    <Slide bottom>
                        <h3>creating <span className="text-transparent bg-clip-text bg-gradient-to-br from-pink-400 to-red-600">impeccable applications</span> with love</h3>
                    </Slide>
                </div>
                <div className="overflow-y-hidden leading-kobi">
                    <Slide bottom delay={300}>
                        <h3>with an <span className="text-transparent bg-clip-text bg-gradient-to-b from-pink-400 to-red-600">astounding speed {`&`} quality</span></h3>
                    </Slide>
                </div>
                <div className="overflow-y-hidden lg:block hidden leading-kobi my-2">
                  <Slide bottom delay={400}>
                    <h3 className="text-xs 2xl:text-base text-gray-500">驚異的なスピードと品質で愛を込めて非の打ちどころのないアプリケーションを作成する</h3>
                  </Slide>
                </div>
            </div>
        </section>

        {/* Text Slider */}
        <div className="overflow-y-hidden z-0">
          <Slide bottom delay={900}>
            <section className="bg-black text-white py-4">
              <Marquee className="z-0 overflow-y-hidden uppercase font-bold text-2xl 2xl:text-4xl" gradient={false} speed={40}>
                <p className="mx-2">Frontend Development</p>
                <p className="z-0 mx-2 text-transparent bg-clip-text bg-gradient-to-b from-pink-400 to-red-600">フロントエンド開発</p>
                <p className="mx-2">Backend Development</p>
                <p className="z-0 mx-2 text-transparent bg-clip-text bg-gradient-to-b from-pink-400 to-red-600">バックエンド開発</p>
                <p className="mx-2">Full-Stack Development</p>
                <p className="z-0 mx-2 text-transparent bg-clip-text bg-gradient-to-b from-pink-400 to-red-600">フルスタック開発</p>
                <p className="mx-2">API Integrations</p>
                <p className="z-0 mx-2 text-transparent bg-clip-text bg-gradient-to-b from-pink-400 to-red-600">統合</p>
              </Marquee>
            </section>
          </Slide>
        </div>

        {/* Project Section */}
        <section className="mt-10 lg:mt-20 max-w-screen-lg mx-auto mb-10">
          <div className="overflow-y-hidden w-full mb-4 text-center">
            <Slide bottom>
              <h3 className="2xl:text-3xl text-2xl font-bold">Some of my works / clients.</h3>
              <p className="text-gray-400">私の作品のいくつか</p>
            </Slide>
          </div>
          <div className="grid lg:grid-cols-2 grid-cols-1 lg:px-0 px-5 mb-10">
            <Project id={"nubiangallery"} desc={"Gallery, Space & Venue."} type={"Frontend & Backend"} title={"Nubian Gallery"} image={NubianWebP} />
            <Project id={"qparcel"} desc={"Parcel Delivery Service"} type={"Frontend"} title={"QParcel"} image={QParcelSVG} />  
          </div>
          <div className="overflow-y-hidden flex justify-center items-center lg:mt-0 mt-4">
            <Slide bottom delay={600}>
              <Link to="/portfolio">
                <button className="hover:text-blue-400 duration-300 text-white px-2 py-1 lg:px-4 lg:py-2 bg-black border hover:border-black shadow-lg uppercase text-lg 2xl:text-2xl font-thin">more ➝</button>
              </Link>
            </Slide>
          </div>
        </section>

        {/* What I Do */}
        <section className="mt-10 lg:mt-40 lg:mb-40 max-w-screen-xl mx-auto mb-10 flex justify-center items-center flex-col lg:px-0 px-4 text-center">
            <div className="overflow-y-hidden w-full mb-4 text-center">
              <Slide bottom>
                <h3 className="2xl:text-3xl text-2xl font-bold">What I work with.</h3>
                <p className="text-gray-400 mb-4">私が扱っているもの</p>
              </Slide>
            </div>
            <div className="overflow-y-hidden w-full">
              <Slide bottom>
                <div className="py-4 lg:flex-row flex-col flex justify-between items-center">
                  <h3 className="text-3xl font-bold text-gray-700 lg:mb-0 mb-2">Front-end Development</h3>
                  <p className="text-sm font-semibold text-gray-500">A business must need a website. Let's make a pretty one.</p>
                </div>
              </Slide>
            </div>
            <div className="overflow-y-hidden w-full">
              <Slide bottom>
                <div className="py-4 lg:flex-row flex-col flex justify-between items-center">
                  <h3 className="text-3xl font-bold text-gray-700 lg:mb-0 mb-2">Back-end Development</h3>
                  <p className="text-sm font-semibold text-gray-500">A website needs to process and store data. Let's process that data.</p>
                </div>
              </Slide>
            </div>
            <div className="overflow-y-hidden w-full">
              <Slide bottom>
                <div className="py-4 lg:flex-row flex-col flex justify-between items-center">
                  <h3 className="text-3xl font-bold text-gray-700 lg:mb-0 mb-2">Mobile App Development</h3>
                  <p className="text-sm font-semibold text-gray-500">Developing a mobile app for your business is a must.</p>
                </div>
              </Slide>
            </div> 

            <div className="overflow-y-hidden">
              <Slide bottom>
                <h4 className="mt-4 text-md text-gray-400">Let's take it to the next step and have a chat with me.</h4>
              </Slide>
            </div>
            <div className="overflow-y-hidden flex justify-center items-center mt-2">
              <Slide bottom delay={600}>
                <a href="mailto:ito.kobilol@gmail.com">
                  <button className="hover:text-blue-400 duration-300 text-white px-2 py-1 lg:px-4 lg:py-2 bg-black border hover:border-black shadow-lg uppercase text-md 2xl:text-xl font-thin">let's have a chat ➝</button>
                </a>
              </Slide>
            </div>

        </section>

        <Footer />

        {/* Navbar Overlay */}
        <NavOverlay scrollWidth={scrollWidth.value} />


    </div>
  );
}

export default MainPage;
