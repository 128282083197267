import NubianGallery from '../assets/images/nubian.webp';
import QParcel from '../assets/images/qparcel.png';
import Kodirati from '../assets/images/kodirati.png';
import CalendarPNG from '../assets/images/calendar.png';
import PollifySVG from '../assets/images/1F5F3.svg';
import DavisPNG from '../assets/images/1F9D4.svg';
import SortSVG from '../assets/images/bar-chart.svg';
import AHES from '../assets/images/ahes.png';
import VITA from '../assets/images/vita.png';

import AHESSite from '../assets/images/AHES (1).png';
import NubianSite from '../assets/images/nubian-site.png';
import QParcelSite from '../assets/images/QParcel (1).png';
import QParcelSite2 from '../assets/images/QParcel (2).png';
import QParcelSite3 from '../assets/images/QParcel (3).png';
import KodiratiSite from '../assets/images/kodirati (1).png';
import TerrySite from '../assets/images/Terry A. Davis (1).png';
import HomeSite from '../assets/images/Homeworker (1).png';
import PollSite from '../assets/images/Pollify - Instant Polls (2).png';
import PollSite2 from '../assets/images/Pollify - Instant Polls (1).png'
import SortSite from "../assets/images/Kobi - Sorting Algo's.png";
import VitaBox from '../assets/images/vita-box.gif';
import VitaMain from '../assets/images/vita-main.png';
import VitaCase from '../assets/images/vita-case.png';
import VitaFaq from '../assets/images/vita-faq.png';
import VitaRaffle from '../assets/images/vita-raffle.png';
import VitaSettings from '../assets/images/vita-settings.png';


const projectArr = {

  vitapvpey: {
    title: "VitaPvPey Boutique",
    smallDesc: "Box Opening Site.",
    description: "Developed / converted their PSD to HTML utilizing TailwindCSS to rapidly deliver a fully functioning website responsively. Developed using Javascript a case / box spinning or rolling animation with sound and plays. As seen on the gif and images below.",
    languages: "html, css, js & tailwindcss",
    image: VITA,
    images: [VitaBox, VitaMain, VitaCase, VitaFaq, VitaRaffle, VitaSettings]
  },

  nubiangallery: {
    title: "Nubian Gallery",
    smallDesc: "Gallery, Space & Venue",
    description: "A full-stack web application made for a client who needed some managing for his art gallery and space renting area. Developing a dashboard integrating with a 3rd party software named 'acuity' to notify and easier manage incoming orders and space rentings.",
    languages: "html, css, js, tailwindcss, expressjs, nodejs, reactjs & mongodb.",
    image: NubianGallery,
    website: "https://www.nubiangalleryroxbury.com/",
    images: [NubianSite]
  },

  ahes: {
    title: "AHES",
    smallDesc: "Household Estate Sales",
    description: "Worked on this website to bring to the table a dynamic back-end system that includes functions as converting images from the client into BASE64 formats to zestfully store them in a host.",
    languages: "html, css, js, react, expressjs, nodejs & sqlite3",
    image: AHES,
    website: "https://www.americanhouseholdestatesales.com/",
    images: [AHESSite]
  },

  qparcel: {
    title: "QParcel",
    smallDesc: "Parcel Delivery Service",
    description: "A front-end web application made for the front-end of their parcel site. Where included development of their main page, dashboard page with some javascript functions.",
    languages: "html, css, tailwindcss & js",
    image: QParcel,
    website: "https://qparcel.vercel.app",
    images: [QParcelSite, QParcelSite2, QParcelSite3]
  },

  kodirati: {
    title: "Kodirati",
    smallDesc: "Java & Bot Developer",
    description: "A client whom is a Minecraft Java Mod developer & a Discord Bot Developer contacted me to make his portfolio so he could display his work and manage the listing and display at an eased manner, which was solved with a dashboard / admin panel.",
    languages: "html, css, js, tailwindcss, handlebars & nodejs",
    image: Kodirati,
    website: "https://kodirati.com",
    images: [KodiratiSite]
  },

  terrydavis: {
    title: "Terry Davis",
    smallDesc: "God's Lonely Programmer",
    description: "Terry Davis was one of the figures who made me and got me into engineering and programming, though he's an Computer Engineer; I'm on my way to become someone like him.",
    languages: "html, css, js, tailwindcss & reactjs",
    image: DavisPNG,
    website: "https://terrydavis.vercel.app",
    images: [TerrySite]
  },

  homeworker: {
    title: "Homeworker",
    smallDesc: "Homework scheduling and tracking.",
    description: "An app made to track my homework, pending activities and manage my time and my upcoming events. Made to help me aid with my work & time management.",
    languages: "html, css, js, tailwindcss, reactjs, expressjs, nodejs, jwt & mongodb",
    image: CalendarPNG,
    website: "https://homework.itskobi.com",
    images: [HomeSite]
  },

  pollify: {
    title: "Pollify",
    smallDesc: "Real-time polling site.",
    description: "An app made for real-time polling, with dynamic links and websockets to deliver real-time events from end-to-end to users. Where a user can select their specific topics and then share the link for their friends to vote and poll.",
    languages: "html, css, js, tailwindcss, reactjs, expressjs, nodejs, sqlite3 & socket.io",
    image: PollifySVG,
    website: "https://pollify-amber.vercel.app/",
    images: [PollSite2, PollSite]
  },

  sortingviz: {
    title: "Sorting Visualizer",
    smallDesc: "Sorting Algorithms Visualized.",
    description: "An app where you can configure your preferences to display how sorting algorithms work, how they sort and calculates the loops, swaps and which algorithm is the best.",
    languages: "html, css, js, tailwindcss & reactjs",
    image: SortSVG,
    website: "https://sorting-visualizer-rouge.vercel.app/",
    images: [SortSite]
  }

};

export default projectArr;