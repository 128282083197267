import React from 'react';
import {Link} from 'react-router-dom';
import Slide from 'react-reveal/Slide';

function Footer({m = true}) {
  return (
    <footer className={`${m ? "mt-10" : "" } bg-black text-gray-100 h-auto p-10 relative flex items-center flex-col`}>
      <div className="overflow-y-hidden">
        <Slide bottom>
          <h1 className="text-3xl text-center font-thin uppercase">I would love to have a chat with you.</h1>
        </Slide>
      </div>
      <div className="overflow-y-hidden">
        <Slide bottom delay={300}>
          <p className="text-center text-gray-500 text-xs">Collaborate with me, I would love to talk with you about your ideas and your projects.</p>
        </Slide>
      </div>

      <a href="mailto:ito.kobilol@gmail.com" className="hover:font-semibold hover:text-red-500 duration-300 text-xl font-thin uppercase mt-4">ito.kobilol@gmail.com</a>

      <div className="grid grid-cols-2 mt-4">
        <div className="m-2 flex flex-col">
          <h3 className="text-base uppercase text-gray-400">Socials.</h3>
          <a href="https://twitter.com/truedarkness420" target="_blank" rel="noreferrer" className="text-sm text-gray-300 duration-300 hover:text-red-500">Twitter</a>
          <a href="https://linkedin.com/in/kobimo" target="_blank" rel="noreferrer" className="text-sm text-gray-300 duration-300 hover:text-red-500">LinkedIn</a>
          <a href="https://github.com/kobito-kun" target="_blank" rel="noreferrer" className='text-sm text-gray-300 duration-300 hover:text-red-500'>GitHub</a>
        </div>
        <div className="m-2 flex flex-col">
          <h3 className="text-base uppercase text-gray-400 ">Menu.</h3>
          <Link to="/" className="text-sm text-gray-300 duration-300 hover:text-red-500">Home</Link>
          <Link to="/portfolio" className="text-sm text-gray-300 duration-300 hover:text-red-500">Portfolio</Link>
          <Link to="/about" className="text-sm text-gray-300 duration-300 hover:text-red-500">About</Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
